import React from "react";
import {Page} from '../components/Page'
import Section from "../components/layouts/Section";

function NotFound(){
	return (
		<Page title="Page Not Found">	
			<Section fullWidth={false}>
				<div className="grid-x text-center" style={{ minHeight: "60vh"}}>
					<div className="column small-12">
						<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Scared_elatnm.png" alt="scared_ashley" style={{width:250}}/>
					</div>
					<div className="column small-12">
					<span style={{fontSize:48}}>
						OOPS! THAT PAGE CAN’T BE FOUND.
					</span>
					</div>

					<div className="column small-12">
					<span> It looks like nothing was found at this location. Try one of the links in the menu to get back on track. </span>
						</div>
			

				</div>
			</Section>
		</Page>
	)
} 

export default NotFound;

