import React, { Component } from 'react'

export default class Loading extends Component{

	render(){
		return(
				<div id="st-loading-logo" className="small-12 cell text-center" style={{top: "35vh", minHeight:"100vh", position:"fixed"}}>
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564948801/Website/Icons/black_logo_200x200_bw1hhg.png" alt="st-loading-logo" />
					<br/>
					<p className="st-loading" style={{fontSize:68}}><span>.</span><span>.</span><span>.</span></p>
				</div>
			)
	}
}