import React, { Component } from 'react';
import { Data } from  '../../../data/CarouselData'

var autoScrollInstance;

export default class Carousel extends Component {
	constructor(props){
		super(props);
		this.state = {
            Refresh: false,
            Index: 0,
            Mobile: false
		}
        this.changeIndex = this.changeIndex.bind(this)
        this.stopLoading = this.stopLoading.bind(this)
        this.autoScroll = this.autoScroll.bind(this)
        this.stopAutoScroll = this.stopAutoScroll.bind(this)
        this.onAutoScroll = this.onAutoScroll.bind(this)
	}

    resize() {
        this.setState({Mobile: window.innerWidth <= 1024});
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    autoScroll()
    {
        autoScrollInstance = setTimeout(this.onAutoScroll, 20000);
    }
    
    onAutoScroll()
    {
        let newIndex = this.state.Index + 1
        if(newIndex >= Data.length)
        {
            newIndex = 0
        }
        this.changeIndexValue(newIndex)
    }
    
    stopAutoScroll()
    {
        clearTimeout(autoScrollInstance)
    }

    changeIndex(event)
    {
       this.setState({
            Refresh: true,
            Index: parseInt(event.target.value)
        })

        setTimeout(this.stopLoading, 200);
    }

    changeIndexValue(index)
    {
       this.setState({
            Refresh: true,
            Index: index
        })

        setTimeout(this.stopLoading, 200);
        this.autoScroll()
    }

    stopLoading()
    {
        this.setState({
            Refresh: false
        })
    }

    componentDidMount()
    {
        this.autoScroll()
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

	render(){
		const { Refresh, Index, Mobile } = this.state
        return(
            <div id="st-header" className={`grid-x text-center st-carousel ${Refresh? "disabled": ""}`} onMouseEnter={this.stopAutoScroll} onMouseLeave={this.autoScroll}>
                {Refresh ?
                    <span></span>:
                    Mobile?
                    <img src={Data[Index].mobileSrc} className="fade-in-video" alt={Data[Index].name}/>
                    :
                    Data[Index].srcType === "video"?
                    <video disablePictureInPicture autoPlay={true} muted={true} loop={true} className="ignore-pointer fade-in-video"> 
                        <source src={Data[Index].src} type="video/mp4" /> 
                    </video>:
                    <img src={Data[Index].src} className="fade-in-video" alt={Data[Index].name}/>
                }

                <div className={`column st-carousel-labels ${Mobile? "st-carousel-mobile": ""}`} style={{zIndex:1}}>
                    <h1 className="st-carousel-label">
                        {Data[Index].title}
                    </h1>

                    <div className="column small-4 text-center" style={{display:"inline-flex", transform:"translate(0,-13px)"}}>
                        <h5><small>{Data[Index].description}</small></h5>
                    </div>
                    
                    <br/>
                    {Refresh ?
                    <span></span>
                    :
                        <a href={Data[Index].url} type="button" className="st-carousel-callToAction">
                            {Data[Index].callToAction}
                        </a>
                    }
                </div>

                <br/>

                <div className="column" style={{ position:"absolute", bottom: 5 }}>
                    <form>
                    <ul className="st-carousel-navigation">
                        {Data.map((content, index) => (
                            <li key={content + index}>
                              <input type="radio" name="carousel" value={index} checked={index === Index} onClick={this.changeIndex}  onChange={e => {}} disabled={Refresh}/>
                            </li>
                        ))}
                    </ul>
                    </form>
                </div>
            </div>
            )
	}
}
