import React, {Component} from "react";
import {Page} from '../components/Page'
import Section from "../components/layouts/Section";
import { Profile } from '../data/AboutData'

function About(){
	return (

			<Page title="The Team" path="/about">	
			<Section fullWidth={true} background="#0a0a0a">
				<div id="st-page-header" className="small-12 grid-x column">
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665390729/Website/Img/the_team_opleft.png" className="fade-in-video st-header-background" alt="the_team" style={{zIndex:-1, filter:"blur(8px)"}}/>
					<div className=" center">
						<h1 style={{textShadow:"2px 2px 5px #000"}}><b>THE TEAM</b></h1>
					</div>
				</div>
			</Section>	
			<Section fullWidth={false} background="#1F1F1F">
                        <div className="small-12 column text-center">
                            <h2><b>ABOUT SNOWTAIL</b></h2>
                        </div>

						<div className="small-12 column text-center">
                          
                        <p style={{maxWidth:"60ch", margin:"auto"}}>
							A Montreal-based independent game studio. Snowtail is run by 3 friends with different talents passionately working together to build great games. 
							We want to build wonderous worlds to explore, filled with charming and memorable characters. 
							All of us started in our mother’s basements and have made it all the way to our own basements! Who knows where we'll go next?
							</p>
						</div>
                    </Section>
			<Section fullWidth={false}>
				{Profile.map((content, index) => (
					<TheDev key={`dev-${index}`} name={content.name} 
					title={content.title}
					img={content.imgSrc}
					hoverImg={content.hoverImgSrc}
					className={content.className}
					background={content.background} 
					text={content.description}
					favouriteTitle={content.favouriteGamesTitle} 
					favouriteGames={content.favouriteGames} />
					))
				}
			</Section>
			</Page>
		)
} 

export default About;


class TheDev extends Component 
{
	constructor(props)
	{
		super(props)
		this.state = {
			isHovered : false
		}

		this.onHoverEnter = this.onHoverEnter.bind(this)
		this.onHoverExit = this.onHoverExit.bind(this)
	}


	onHoverEnter()
	{
		this.setState({
			isHovered: true
		})
	}

	onHoverExit()
	{
		this.setState({
			isHovered: false
		})
	}


	render()
	{
		const { name, title, img, hoverImg, text, background, className, favouriteTitle, favouriteGames} = this.props
		const { isHovered } = this.state

		return(
			<div className="large-4 small-12">
				<div className="small-12 show hide-in-large st-profile-divider" />
				<div className="st-dev-img-container"style={{backgroundColor:background}} onMouseEnter={this.onHoverEnter} onMouseLeave={this.onHoverExit}>
					{isHovered?
						<img src={hoverImg} alt={name} className="fade-in" />
						:
						<img src={img} alt={name} className={`st-profile-img fade-in ${className}`} />
					}
				</div>
				<div className="column small-12 st-profile-description-box" style={{padding:10}}>
					<h3 style={{lineHeight:1,textTransform:"uppercase"}}><b>{name}</b></h3>
					<h5>{title}</h5>
					<br/>
					<p style={{paddingLeft:20, paddingRight:20}}>
						{text}
					</p>
				</div>
				<div className="column small-12" style={{padding:10}}>
					<h5><b>{favouriteTitle}</b></h5>
					<ul className="no-bullet">
					{favouriteGames.map((content, index) => (
						<li key={`favourite-game-${index}`}>{content}</li>
						))
					}
					</ul>
				</div>
			</div>
		)
	}
}