import React, { Component } from 'react';

export default class FloatingLabelInput extends Component{

	render(){
		const { className, type, isFocused, onFocus, onBlur, onChange, styleClass } = this.props
		return(
			<div className={className} style={{position:'relative', paddingTop:10, marginRight:20}}>
				<input type={type} 
				className={styleClass}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange} 
				placeholder="Email Address"/>
			</div>
			)
	}
}