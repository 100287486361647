import React, { Component } from 'react';
import validator from 'validator';
import FloatingLabelInput from './FloatingLabelInput'
export default class MailchimpForm extends Component {

	constructor(props){
		super(props);
		this.state = {
			email: "",
			isFocused: false,
			message: "",
			isLoading: false,
			isPassed: false,
		}

		this.handleSubscriptions = this.handleSubscriptions.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnUnfocused = this.handleOnUnfocused.bind(this);
	}

	handleEmailChange(event){
		this.setState({
			email: event.target.value,
			isPassed : false,
			message: ""
		})
		event.preventDefault();
	}

	handleSubscriptions = async (event) => {
		event.preventDefault();
		this.setState({
			isLoading: true
		})
		await fetch(`/api/v1/subscribe/?email=${this.state.email}`)
		.then(res => res.json())
		.then(result => {
			var status = result.statusCode || result.status 
			var title = result.title
			if(status === 400){
				if(title === "Member Exists"){
					this.setState({
						message: "This email is already in our mailing list!",
						isPassed: false,
						isLoading: false
					})
				}
				else if(title === "Invalid Resource")
				{
					this.setState({
						message: `${this.state.email} is invalid, please enter another email address.`,
						isPassed: false,
						isLoading: false
					})
				}
				else{
					this.setState({
						message: `An error has occured. Please try again`,
						isPassed: false,
						isLoading: false
					})
				}
			}
			else if(status === 200){
				this.setState({
					message: ``,
					isPassed: true,
					isLoading: false
				})
			}
			else{
				this.setState({
					message: `An error has occured. Please try again`,
					isPassed: false,
					isLoading: false
				})
			}
		})
		.catch(err => console.log(err))
	}

	handleOnFocus(event){
		event.target.placeholder = ""
		this.setState({
			isFocused: true
		})
	}

	handleOnUnfocused(event){
		if(this.state.email === ""){
			this.setState({
				isFocused: false
			})
		}
	}

	render(){
		return (

		  	<div className="small-12 column grid-x" >
					<form onSubmit={this.handleSubscriptions} className="grid-x column small-12" style={this.props.styles}>
							<FloatingLabelInput className="small-12 medium-8 column" 
					            					type="email"
					            					isFocused={this.state.isFocused}  
					            					onFocus={this.handleOnFocus}
					            					onBlur={this.handleOnUnfocused}
					            					onChange={this.handleEmailChange} 
													styleClass={this.props.styleClass}
													 />
							{this.state.isLoading ? 
								<LoadingAnim />
								:<div className="small-12 medium-2 column grid-x" >
										{this.props.bridgeKnights ? <span></span> :  
										<div className="small-12 column grid-x" >
											{this.state.isPassed ? <CheckMarks/> : 
												<button type="button" onClick={this.handleSubscriptions} disabled={!validator.isEmail(this.state.email)} style={{margin:"auto", padding:20}}>
													Subscribe
												</button>
												}

										</div>
										}
					        	</div>
								}

								<div className="small-12 cell" >
									<p>
										{this.state.message !== "" &&
											<span style={this.state.isPassed ? {color: "green"} : {color: "red"}}>{this.state.message} </span>
										}
									</p>
					        	</div>
								
					</form>
		  	</div>
		)
	}
}

class LoadingAnim extends Component {
	render(){
		return(
			<div className="small-12 medium-1">
				<div className="lds-css ng-scope   grid-x align-center">
					<div className="lds-spinner cell" style={{width: '100%' , height:'100%'}}>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		)
	}
}


class CheckMarks extends Component{
	render(){
		return(
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
			<circle className="path circle" fill="none" stroke="green" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
				<polyline className="path check" fill="none" stroke="green" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
			</svg>
		)
	}
}