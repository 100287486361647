import React, { Component } from 'react';
import MailchimpForm from '../../MailchimpForm';
import Section from '../Section';

export default class NewsLetterSection extends Component {
	
	render(){
		return(
				<Section fullWidth={false}>
					<div className="grid-x hide-for-small-only hide-for-medium-only" style={{minHeight:290}}>
						<div className="column large-3 text-left" style={{ position:"relative", top:-40,}}>
							<img style={{position:"relative", width: 250}}src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Talking_xfupll.png" className="hide-for-small-only hide-for-medium-only st-flipped" alt="Ashley" />
						</div>
						<div className="column grid-x small-12 large-8 text-left" style={{ position:"relative", padding:"0 20px", left:-50,}}>
							<img style={{position:"relative", top:40, height:250}} src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" className="hide-for-small-only hide-for-medium-only" alt="speech_buble" />
							<div className="column grid-x small-12 large-8 text-left" style={{ position:"absolute", top:100, left:160}}>
								<h2 style={{zIndex:2}}>JOIN OUR NEWSLETTER</h2>
								<br/>
								<MailchimpForm styleClass="st-input-text floating-label"/>
							</div>
						</div>
					</div>
					<div className="column grid-x small-12 hide-for-large text-left" style={{padding:"0 20px"}}>
						<h2>JOIN OUR NEWSLETTER</h2>
						<br/>
						<MailchimpForm styleClass="st-input-text floating-label"/>
					</div>
				</Section>
			)
	}
}