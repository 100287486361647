import React, {Component} from 'react'
import Loading from './Loading'
import Footer from './layouts/Footer'
import {Helmet} from 'react-helmet'


export class Page extends Component{
	constructor(){
		super()
		this.state= {
			loading: true
		}
	}

	componentDidMount(){
		const self = this
		setTimeout(function(){ self.setState({loading:false}) }, 2000);
  		document.title = this.props.title + " - Snowtail" ;
	}


	render(){
		const {children, path, title} = this.props
		const {loading} = this.state
		
		return(		
			<div id="st-main-container" className=" grid-x st-adjust-to-nav">
			<Helmet>
			<meta name="twitter:title" content={`${title} - Snowtail`}/>
			<meta property="og:title" content={`${title} - Snowtail`} />
			<meta property="og:url" content={`https://www.snowtailgames.com${path}`} />
			<link rel="canonical" href={`https://www.snowtailgames.com${path}`} />
			</Helmet>
			<div className="small-12 column fade-in">
				{children}
			</div>	
			<div id="st-footer" className="small-12 column">
				<Footer 
					twitter="https://twitter.com/SnowtailGames"
					facebook="https://www.facebook.com/SnowtailGames" 
					email= "mailto:info@snowtailgames.com" /> 

			</div>			
			</div>
			)
	}
}