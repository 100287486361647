import React, { Component } from 'react';
import GamesData from  '../../../data/GamesData.json'
import Section from '../Section';

export default class FeaturedGames extends Component {
	
	render(){
		let darkChapter = GamesData["darkChapters"]
		let bridgeKnights = GamesData["bridgeKnights"]
		return(
				<Section fullWidth={false}>
					<div className="column grid-x small-12 text-left text-center-in-small">
						<div className="small-12 column">
						<h2 style={{fontSize:36}}>Our Games</h2>
						</div>
						<div className="small-12 medium-6 column">
							<a href={darkChapter.contentUrl} className="st-featured-game">
								<img src={darkChapter.featureImgSrc} alt={darkChapter.title}></img>
							</a>	
						</div>

						<div className="small-12 medium-6 column">
							<a href={bridgeKnights.contentUrl} className="st-featured-game">
								<img src={bridgeKnights.featureImgSrc} alt={bridgeKnights.title}></img>
							</a>	
						</div>
					</div>
				</Section>
			)
	}
}