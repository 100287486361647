import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'

export default class Navigation extends Component{

	constructor(props)
	{
		super(props)
		this.state = {
			navOpen: false
		}
		this.toggleMenu = this.toggleMenu.bind(this)
	}


	toggleMenu(){
		this.setState({
			navOpen: !this.state.navOpen
		})
	}

	render(){

		const { navOpen } = this.state
		return(
			<nav id="st-navbar" className="grid-x" >
				<div id="st-navbar-container" className={`column small-12 grid-x ${navOpen?"open":""}`}>
					<div className="column small-8 large-4 ">
						<LeftNav />
					</div>

					<div className="column small-12 large-8">
						<RightNav toggleMenu={this.toggleMenu} navOpen={this.state.navOpen}/>
					</div>
				</div>
			</nav>

			)
	}
}


class LeftNav extends Component{

	render(){
		return(

			<div className="grid-x">
				<div id="st-logo"className="column small-12">
					<a href="/" style={{pointerEvents:"visible"}}>
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442173/Website/Logo/156444206ww917657637_transparent_ome38c.png" className="st-logo-nav" alt="logo"/>
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442058/Website/Logo/156444206917657637_transparent_ic8u9o.png" className="st-logo-nav-name" alt="logo"/>
					</a>
				</div>
			</div>
		)
	}
}

class RightNav extends Component{

	render(){

		const activeMenu = {color:"#fff", fontWeight:400}
		const { toggleMenu, navOpen } = this.props
		const textStyle = {fontSize:20}
		return(

			<div id="st-navbar-main" className={`small-12 grid-x ${navOpen?"open":""}`}>
				<button className="menu-icon st-menu-icon text-right hide-for-large" onClick={toggleMenu}/>
				<div id="st-navbar-menu" className={`${navOpen?"open":""} small-12 grid-x`}>
 
					<NavLink to="/dark-chapters" activeStyle={activeMenu} onClick={toggleMenu}>
							<span id="navItem" className="st-navigation-menu">Dark Chapters</span> 
					</NavLink>
					<NavLink to="/bridge-knights" activeStyle={activeMenu} onClick={toggleMenu}>
							<span id="navItem" className="st-navigation-menu">Bridge Knights</span> 
					</NavLink>

					<NavLink to="/about" activeStyle={activeMenu} onClick={toggleMenu}>
							<span id="navItem" className="st-navigation-menu">The Team</span> 
					</NavLink>

					<NavLink to="/contact" activeStyle={activeMenu} onClick={toggleMenu}>
							<span id="navItem" className="st-navigation-menu">Contact</span> 
					</NavLink>
					
				</div>
			</div>
		)
	}
}