import React, { Component } from 'react';
import Section from '../Section';

export default class AboutSnowtail extends Component {
	
	render(){
		return(
			<Section fullWidth={false} background="#1F1F1F">
				<div className="colum small-12" style={{paddingBottom:30}}>
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442058/Website/Logo/156444206917657637_transparent_ic8u9o.png" style={{}} alt="logo"/><br/>
					<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665120480/Website/Gifs/The_Team_full_team_tight_itg6bs.gif" alt="the_team" style={{width:500}}/>
					<br/>
					<p>A Montreal-based independent game studio. Snowtail is run by 3 friends with different<br/>talents passionately working together to build great games.</p>
					<br/>	
					<a href="/about" className="link-button">
                        About Us
                    </a>
				</div>
			</Section>
		)
	}
}