import React, { Component } from "react";
import GamesData from '../data/GamesData.json'
import GamePage from '../components/layouts/GamePage'
import Section from "../components/layouts/Section";
import MailchimpForm from "../components/MailchimpForm";

export default class DarkChapters extends Component {
	constructor(props)
	{
		super(props)
		this.state ={
			ashleyIndex: "default",
			ashleyFaces:{
				default : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Default_k5wm4j.png",
				scared : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Scared_elatnm.png",
				talking: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Talking_xfupll.png",
			    pain: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Ash_Pain_scvofa.png",
            },
            boyIndex: "default",
			boyFaces:{
				default : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Boy_Default_meu1ql.png",
				scared : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370620/Website/Img/Dark%20Chapters/Faces/Boy_Scared_egvqs2.png",
				talking: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Boy_Talking_m1vifd.png",
				pain: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Boy_Pain_bn56yx.png",
            },
            grandpaIndex: "default",
			grandpaFaces:{
				default : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Grandpa_Default_qyndyt.png",
				scared : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Grandpa_scared_t9ny9c.png",
				talking: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370618/Website/Img/Dark%20Chapters/Faces/Grandpa_Talking_vqdbqn.png",
				pain: "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370619/Website/Img/Dark%20Chapters/Faces/Grandpa_Pain_du6ijz.png",
            },
            refresh: false,
		}
		this.onHandleChangeFace = this.onHandleChangeFace.bind(this)
		this.onClickChangeFace = this.onClickChangeFace.bind(this)
	}

    onHandleChangeFace(character, face)
    {
        if(this.state[character] === "talking")
        {
            return
        }
        this.setState({[character]: face})
    }

    onClickChangeFace(character, face)
    {
        this.setState({
            ashleyIndex: "default",
            boyIndex: "default",
            grandpaIndex: "default"
        })
        this.onHandleChangeFace(character,face)
    }

    ashley()
    {
        var game = GamesData["darkChapters"];
        const { ashleyIndex, ashleyFaces} = this.state
        return(
            <Section fullWidth={false}>
            <div className="small-12 large-5 column text-left" style={{justifyContent:"center", blockSize:"fit-content"}}>
            <h2><b>ABOUT THE GAME</b></h2>
                <p>
                {game.description[0]}
                </p>
                <br/>
                <p>
                {game.description[1]}
                </p>
            </div>

            <div className="large-7 grid-x hide-for-small-only hide-for-medium-only">
                <div className="large-4 column text-right" style={{position:"relative"}}>
                    <img src={ashleyFaces[ashleyIndex]} alt="ashley" 
                        className="st-flipped"
                        onMouseEnter={() => { this.onHandleChangeFace("ashleyIndex", "scared")}} 
                        onMouseLeave={() => this.onHandleChangeFace("ashleyIndex", "default")} 
                        onClick={() =>this.onClickChangeFace("ashleyIndex", "talking")}
                        style={{cursor:"pointer", position:"relative", top:40, width:250}}/>
                </div>

                <div className="large-6 column text-left" style={{position:"relative", left:-20}}>
                    <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:160, left:-20, minWidth:396}} className={`st-speech-bubble ${ashleyIndex === "talking" ? "visible":""} `} />
                    {ashleyIndex === "talking" ?
                    <p className="fade-in" style={{position:"absolute", textAlign:"left", bottom: 38, left:65, maxWidth:"30ch"}}>My bro is NOT gonna like that part about DARK depths</p>:
                    <span></span>}
                </div>
            </div>

            <div className="small-12 grid-x hide-for-large hide-for-smallest-devices">
                <div className="small-4 column text-right" style={{position:"relative"}}>
                    <img src={ashleyFaces["talking"]} alt="ashley" className="st-DC-face-adjust-to-smallest"/>  </div>

                <div className="small-8 column text-left" style={{position:"relative"}}>
                    <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:90, width: 254}} />
                    <p style={{position:"relative", textAlign:"left", bottom: -22, left: 48,  maxWidth:"20ch"}}><small>My bro is NOT gonna like that part about DARK depths</small></p>
                </div>
            </div>
        </Section>
        )
    }

    boy()
    {
        var game = GamesData["darkChapters"];
        const { boyIndex, boyFaces} = this.state
        return (
        <Section fullWidth={false} background="#1F1F1F">
        <div className="large-6 grid-x hide-for-small-only hide-for-medium-only" style={{position:"relative", top:60}}>
            <div className="large-7 column text-left" style={{position:"relative"}}>
                <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:160, left: 50, transform:"rotateY(180deg)"}} className={`st-speech-bubble ${boyIndex === "talking" ? "visible":""} `}/>
                {boyIndex === "talking" ?
                    <p className="fade-in" style={{position:"absolute", textAlign:"left", top: 205, left:80, maxWidth:"30ch"}}>Cooperate? With sis? No thanks!</p>:
                <span></span>}
            </div>

            <div className="small-4 large-5 column text-right" style={{position:"relative"}}>
                <img src={boyFaces[boyIndex]} alt="boy" 
                    onMouseEnter={() => { this.onHandleChangeFace("boyIndex", "scared")}} 
                    onMouseLeave={() => this.onHandleChangeFace("boyIndex", "default")} 
                    onClick={() =>this.onClickChangeFace("boyIndex", "talking")}
                    style={{cursor:"pointer",position:"relative", top:40}}/>
            </div>
        </div>
      
        <div className="small-12 large-6 column text-left">
            <h2><b>FEATURES</b></h2>
            <ul style={{justifyContent:"center"}}>
            {game.features.map((content,index)=>
                <li key={`features-${index}`} style={{marginBottom:15}}>
                    {content}
                </li>
            )}
            </ul>
        </div>

        <div className="small-12 grid-x hide-for-large hide-for-smallest-devices">
            <div className="small-6 column text-right" style={{position:"relative"}}>
                <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:110, right: 0, transform:"rotateY(180deg)", width: 254}} />
                <p className="fade-in" style={{position:"absolute", textAlign:"left", top: 125, right:40, maxWidth:200, paddingLeft:20}}><small>Cooperate? With sis? No thanks!</small></p>

            </div>

            <div className="small-6 column text-left" style={{position:"relative"}}>
                <img src={boyFaces["talking"]} alt="boy" className="st-DC-face-adjust-to-smallest"/>
            </div>
        </div>
        
        </Section>
        )
    }
    
    render(){
	var game = GamesData["darkChapters"];
    const { grandpaIndex, grandpaFaces } = this.state
	return (
			<GamePage game={game} callToActionText="Follow the development" path="/dark-chapters">
				
                {this.ashley()}

                {this.boy()}
                <Section fullWidth={false}>
					<div className="small-12 large-5 column text-left st-DC-newsletter">
                             <h2 style={{zIndex:2}}>STAY UPDATED</h2>
                            <MailchimpForm styleClass="st-input-text floating-label"/>
					</div>

                    <div className="small-12 large-7 grid-x hide-for-small-only hide-for-medium-only" style={{position:"relative", top:-50}}>
                        <div className="small-4 large-6 grid-x text-right">
                            <img src={grandpaFaces[grandpaIndex]} alt="grandpa" 
                                className="st-flipped"
                                onMouseEnter={() => { this.onHandleChangeFace("grandpaIndex", "scared")}} 
                                onMouseLeave={() => this.onHandleChangeFace("grandpaIndex", "default")} 
                                onClick={() =>this.onClickChangeFace("grandpaIndex", "talking")}
                                style={{cursor:"pointer", position:"relative", top:40, width:250}}/>
                        </div>

                        <div className="small-8 large-6 column text-left" style={{position:"relative", left:-90, top: 20}}>
                            <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:160, left:-20}} className={`st-speech-bubble ${grandpaIndex === "talking" ? "visible":""} `} />
                            {grandpaIndex === "talking" ?
                                <p className="fade-in" style={{position:"absolute", textAlign:"left", top:195, left:50, maxWidth:"25ch"}}>Newsletter mhm. I hope it's good news!</p>:
                            <span></span>}
                        </div>
                    </div>

                    <div className="small-12 grid-x hide-for-large hide-for-smallest-devices">
                        <div className="small-3 grid-x text-right">
                            <img src={grandpaFaces["default"]} alt="grandpa" className="st-flipped st-DC-face-adjust-to-smallest" />
                        </div>

                        <div className="small-8 column text-left" style={{position:"relative"}}>
                            <img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665370650/Website/Img/Dark%20Chapters/Speech_bubble_small_bnybhb.png" alt="speech bubble" style={{position:"relative", top:60, width: 254}}/>
                            <p className="fade-in" style={{position:"absolute", textAlign:"left", top:80, left:50, maxWidth:"20ch"}}><small>Newsletter mhm. I hope it's good news!</small></p>
                        </div>
                    </div>
				</Section>
			</GamePage>
		)
    }
} 

