import React, { Component } from "react";
import {Page} from '../Page'
import Section from '../layouts/Section';
import MailchimpForm from "../MailchimpForm";

export default class GamePage extends Component
{
    constructor(props)
    {
        super(props)
        this.state=
        {
            callToActionPressed:false,
            mobile: false
        }

        this.handleClickCallToAction = this.handleClickCallToAction.bind(this);
    }

    resize() {
        this.setState({mobile: window.innerWidth <= 1024});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    componentDidMount()
    {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }


    handleClickCallToAction()
    {
        this.setState({
            callToActionPressed: true
        })
    }

    render()
    {
        const { callToActionPressed , mobile } = this.state
        const {game, callToActionText, children, path} = this.props
        return (
                <Page title={game.title} path={path}>	
                    <Section fullWidth={true} >
                        <div id="st-page-header" className="small-12 column games text-center">
                        {mobile?
                        <img src={game.mobileSrc} className="fade-in-video st-header-background" alt={game.name}/>
                            :
                                game.srcType === "video"?
                                <video disablePictureInPicture autoPlay={true} muted={true} loop={true} className="ignore-pointer fade-in-video"> 
                                    <source src={game.src} type="video/mp4" /> 
                                </video>
                                :
                                <img src={game.src} className="fade-in-video" alt={game.name}/>
                        }
                
                            <img id="st-game-logo" src={game.logo} alt={game.name} />
                            {callToActionPressed?
                                <div className="small-4 column text-left st-mailchimp-game fade-in" style={{justifyContent:"center", blockSize:"fit-content"}}>
                                    <MailchimpForm styleClass="st-input-text floating-label"/>
                                </div>  
                                 :
                                <button id="st-game-call-to-action" onClick={this.handleClickCallToAction}>{callToActionText}</button>
                            }
                        </div>
                    </Section>
                    {children}
                </Page>
            )
    }
} 