import React from "react";
import {Page} from '../components/Page'
import Carousel from "../components/layouts/Home/Carousel";
import FeaturedGames from "../components/layouts/Home/FeaturedGames";
import AboutSnowtail from "../components/layouts/Home/AboutSnowtail";
import NewsLetterSection from "../components/layouts/Home/NewsLetterSection";

function Home(){
	return (
			<Page title="Home" path="">	
				<Carousel/>
				<FeaturedGames/>
				<AboutSnowtail/>
				<NewsLetterSection />
			</Page>
		)
} 

export default Home;
