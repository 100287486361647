import React, { Component } from 'react';
import Section from './Section';
import MailchimpForm from '../MailchimpForm';

export default class Footer extends Component {

	render(){
		const {twitter, email, } = this.props

		return(
				<Section fullWidth={false} background="#0a0a0a">
					
					<div id="st-social-medias" className="column hide-for-small-only hide-for-medium-only large-3">
						<div id="st-logo"className="column small-12">
							<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442173/Website/Logo/156444206ww917657637_transparent_ome38c.png" className="st-logo-nav" alt="logo"/>
							<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564442058/Website/Logo/156444206917657637_transparent_ic8u9o.png" className="st-logo-nav-name" alt="logo"/>
						</div>
					</div>
					<div id="st-social-medias" className="column small-12 large-3">
						<p style={{margin:0}}>Connect with us!</p>
						<ul id="st-social" className="no-bullet menu">
							<li>
								<a href={twitter} target="_blank" rel="noopener noreferrer">
									<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564945830/Website/Icons/twitter-icon_iy1yl6.png" alt="twitter-icon" />
								</a>
							</li>
					
							<li>
								<a href={email} target="_blank" rel="noopener noreferrer">
									<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564945830/Website/Icons/email-icon_femlcs.png" alt="email-icon" />
								</a>
							</li>
						</ul>
					</div>

					<div className="column small-12 large-6">
					<div className="column grid-x small-12 large-8 text-left" style={{padding:"0 20px"}}>
						<p style={{margin:0}}>NEWSLETTER</p>
						<MailchimpForm styleClass="st-input-text floating-label"/>
					</div>
					</div>
					<div className="small-12 cell text-center" >
						<span><small>&#64;Snowtail 2022. All Rights Reserved.</small></span>
					</div>
				</Section>
			)
	}

}