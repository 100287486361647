import React from "react";
import {Page} from '../components/Page'
import Section from "../components/layouts/Section";

function Contact(){
	const twitter ="https://twitter.com/SnowtailGames"
	const email= "mailto:info@snowtailgames.com"
	return (
            <Page title={"Contact"} path="/contact">

            <Section fullWidth={true} background="#0a0a0a">
				<div id="st-page-header" className="small-12 grid-x column">
				<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665392135/Website/Img/Contact_US_ksiwzq.png" className="fade-in-video st-header-background" alt="the_team" style={{zIndex:-1, filter:"blur(8px)"}}/>
					<div className=" center">
						<h1 style={{textShadow:"2px 2px 5px #000"}}>GET IN TOUCH!</h1>
					</div>
				</div>
			</Section>


			<Section fullWidth={false}>
				<div className="small-12 large-6 column st-contact-container" style={{padding:20}}>
					<div className="text-left column">
						<h2><b>Social Media</b></h2>
						<p>Follow us on all of your favorite social channels and so that we can keep you in the loop!  Or sign up for our email newsletter at the very bottom of this page.</p>
						<ul className="menu no-bullet text-left">
							<li>
								<a href={twitter} target="_blank" rel="noopener noreferrer">
									<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1564945830/Website/Icons/twitter-icon_iy1yl6.png" alt="twitter-icon" style={{height:35}}/>
								</a>
							</li>

						</ul>
						<br/>
						<br/>
						<h3><b>General Enquiries</b></h3>
						<p>Do you have a support request or question?  Get in touch!</p>
						<br/>
						<a href={email} className="link-button" target="_blank" rel="noopener noreferrer">
							Contact Us!
						</a>
					</div>
				</div>

				<div className="hide-for-small-only large-6 column st-contact-container" style={{padding:20}}>
					<div className="text-center column">
						<img src="https://res.cloudinary.com/dnhvfarsi/image/upload/v1665394561/Website/Img/booth_i1fvcm.jpg" alt="booth"/>
					</div>
				</div>
			</Section>
            </Page>
    )
}
    
export default Contact