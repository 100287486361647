export const Data = [
    {
        "title":"Dark Chapters",
        "description":"A Horror Adventure game played from multiple perspectives",
        "src":"https://res.cloudinary.com/dnhvfarsi/video/upload/v1664682053/Website/Videos/Dark_Chapters_Bathroom_web_gqqgqh.mp4",
        "srcType":"video",
        "imgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1664770310/Website/Img/Mobile_Dark_Chapters_rum242.png",
        "featureImgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1664774404/Website/Img/Dark_Chapters_Featured_Game_Banner_haejiw.png",
        "mobileSrc" : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1664770310/Website/Img/Dark%20Chapters/Mobile_Dark_Chapters_rum242.png",
        "url":"/dark-chapters",
        "type": "game",
        "callToAction": "Explore"
    },
    {
        "title":"Bridge Knights",
        "description":"A boardgame about building bridges, befriending wacky knights and rescuing your king!",
        "src":"https://res.cloudinary.com/dnhvfarsi/video/upload/v1664767777/Website/Videos/Bridge_Knights_Website_small.mp4_yba5ol.mp4",
        
        "srcType":"video",
        "imgSrc":"",
        "featureImgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/c_scale,w_800/v1664774403/Website/Img/Bridge_Knights_Featured_Game_Banner_arokae.png",
        "mobileSrc" : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665391171/Website/Img/Bridge%20Knights/static_2_bozwgv.png",
        "url":"/bridge-knights",
        "type": "game",
        "callToAction": "Onward"
    },
    {
        "title":"The Team",
        "description":"The developers behind the games",
        "src":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1665119905/Website/Gifs/The_Team_full_team_with_bg_2_zid0im.gif",
        "srcType":"image",
        "imgSrc":"",
        "featureImgSrc":"",
        "mobileSrc" : "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665119905/Website/Gifs/The_Team_full_team_with_bg_2_zid0im.gif",
        "url":"/about",
        "type": "profile",
        "callToAction": "About Us"
    }
]
