import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import './Styles/index.scss';
import $ from 'jquery';
import 'foundation-sites';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './pages/Home'
import DarkChapters from './pages/DarkChapters'
import BridgeKnightsHome from './pages/BridgeKnights'
import About from './pages/About'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import ScrollToTop from './components/layouts/ScrollToTop';

import Navigation from './components/layouts/Navigation'
import HttpsRedirect from 'react-https-redirect';

class Main extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			isOpen: false
		}
	}
	
	toggleMenu(){
		this.setState({
			isOpen: !this.state.isOpen
		})
	}
	
 
    componentDidMount(){
		$(document).foundation(); 
	}
	
	render(){		
		return(
			<HttpsRedirect>
				<Router>
					<ScrollToTop>
						<Navigation isOpen={this.state.isOpen} toggleMenu={this.toggleMenu.bind(this)} /> 
						<Switch>
							<Route exact path="/" component={Home}/>
							<Route exact path="/dark-chapters" component={DarkChapters}/>
							<Route exact path="/bridge-knights" component={BridgeKnightsHome}/>
							<Route exact path="/about" component={About}/>
							<Route exact path="/contact" component={Contact}/>
							<Route path="*" component={NotFound} status={404}/>
						</Switch>
					</ScrollToTop>
				</Router>
			</HttpsRedirect>
			)
	}
}


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Main />);

