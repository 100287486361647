import React, { Component } from 'react';

export default class Section extends Component {

	render(){
		const { fullWidth, className, background, children} = this.props

		return(
                <div className={`grid-x text-center`}  style={{backgroundColor: background, padding:"0 5px"}}>
					<div className={`grid-x small-12 ${fullWidth ?"":"st-section"}${className ? className : ""}`}>
						{children}
					</div>
				</div>
			)
	}

}