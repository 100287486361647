export const Profile = [
    {
        "name": "Hasan Al Salman",
        "title":"Artist & Designer",
        "imgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1665122775/Website/Img/TheDevs/Hasan_kloqgm.gif",
        "hoverImgSrc": "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665126095/Website/Img/TheDevs/Hasan_Profile_m67evs.png",
        "className": "st-profile-hasan",
        "background": "#77d0f7",
        "description": "Is a game designer and art director for Snowtail projects. When not working in the digital space, Hasan spends his time making boardgames or chugging coffee and dreaming up endless ideas that he will probably never get to.",
        "favouriteGamesTitle": "3 Games that inspire Hasan",
        "favouriteGames": ["Metroid Prime", "Outer Wilds", "The Legend of Zelda : Windwaker"]
    },
    {
        "name": "Ahmed Al Salman",
        "title":"Composer/SFX & Designer",
        "imgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1665125756/Website/Img/TheDevs/Ahmed_ehwzlc.gif",
        "hoverImgSrc": "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665156976/Website/Img/TheDevs/Ahmed_Profile_kp7kmd.png",
        "className": "st-profile-ahmed",
        "background": "#93ed80",
        "description":"Is a game designer, composer and sfx artist. When not working on Snowtail projects, Ahmed is making SFX for the upcoming horror game Hiboka! Ahmed is also one third of the game dev Youtube channel Lets Playtest!",
        "favouriteGamesTitle": "3 Games that inspire Ahmed",
        "favouriteGames": ["Paper Mario Thousand Year Door","The Legend of Zelda : Windwaker","Undertale"]
    },
    {
        "name": "Tony Doan",
        "title":"Tech Director",
        "imgSrc":"https://res.cloudinary.com/dnhvfarsi/image/upload/v1665125756/Website/Img/TheDevs/Running_Tony_fxx8fn.gif",
        "hoverImgSrc": "https://res.cloudinary.com/dnhvfarsi/image/upload/v1665125756/Website/Img/TheDevs/Tony_Profile_vefsx8.png",
        "className": "st-profile-tony",
        "background": "#fb5353",
        "description":"The technical director and generala programmer at Snowtail! When not making games, Tony can be found cooking up amazing dishes from his formal training in the cullinary arts! Tony also has a storied background in web dev! Can’t you tell?",
        "description":"The technical director and general programmer at Snowtail! When not making games, Tony can be found cooking up amazing dishes from his formal training in the cullinary arts! Tony also has a storied background in web dev! Can’t you tell?",
        "favouriteGamesTitle": "3 Games that inspire Tony",
        "favouriteGames": ["Nier Automata", "The Legend of Zelda : A Link to the Past", "Super Mario RPG"]
    }
]
